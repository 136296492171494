/* import __COLOCATED_TEMPLATE__ from './standalone-access-level-selector-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import {
  FULL_ACCESS_ROLE_NAME,
  VIEW_ONLY_ROLE_NAME,
  NO_ACCESS_ROLE_NAME,
} from 'embercom/components/table-cells/teammate-list/standalone-access-level-selector';

interface Signature {
  Args: {
    componentAttrs: {
      role: any; // apps/embercom/app/models/role.js
    };
  };
  Element: HTMLDivElement;
}

export default class StandaloneAccessLevelSelectorDropdownItem extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  get icon() {
    if (this.args.componentAttrs.role.name === NO_ACCESS_ROLE_NAME) {
      return 'unsubscribed';
    } else if (this.args.componentAttrs.role.name === VIEW_ONLY_ROLE_NAME) {
      return 'eye';
    } else {
      return 'list';
    }
  }

  get description() {
    if (this.args.componentAttrs.role.name === NO_ACCESS_ROLE_NAME) {
      return this.intl.t(
        'table-cells.teammate-list.fin-standalone-access-cell.no-access-description',
      );
    } else if (this.args.componentAttrs.role.name === VIEW_ONLY_ROLE_NAME) {
      return this.intl.t(
        'table-cells.teammate-list.fin-standalone-access-cell.view-only-description',
      );
    } else if (this.args.componentAttrs.role.name === FULL_ACCESS_ROLE_NAME) {
      return this.intl.t(
        'table-cells.teammate-list.fin-standalone-access-cell.full-access-description',
      );
    }

    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::StandaloneAccessLevelSelectorDropdownItem': typeof StandaloneAccessLevelSelectorDropdownItem;
    'table-cells/teammate-list/standalone-access-level-selector-dropdown-item': typeof StandaloneAccessLevelSelectorDropdownItem;
  }
}
